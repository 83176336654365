body, html {
    font-family: "IBM Plex Sans", Arial, Helvetica, sans-serif;
}

body {
    padding-top: 80px;
}

.rs-navbar-brand {
    font-weight: bold;
    font-size: 1.4em;
}

.filter-panel {
    border-top: solid 1px #ddd;
    border-bottom: solid 1px #ddd;
    border-radius: 0;
}

.filter {
    margin-bottom: 15px;
}

.readme {
    max-width: 600px;
    font-size: 18px;
    line-height: 28px;
}

.filterContext {
    margin-bottom: 15px;
}

/* Purple: '#8553a5';
LightPurple; '#d4c6de'; */
:root {
    --rs-navbar-inverse-bg: #000;
    --rs-navbar-inverse-text: #fff;
    --rs-navbar-inverse-hover-bg: #8553a5;
    --rs-navbar-inverse-hover-text: #fff;
    --beacon-active-nav-bg: #fff;
    --beacon-active-nav-text: #000;
}

.rs-navbar-item {
    box-sizing: border-box;
    border-top: solid 8px var(--rs-navbar-inverse-bg);
}

.rs-navbar-item.active:focus,
.rs-navbar-item.active,
.rs-navbar-item.active:hover {
    background-color: var(--beacon-active-nav-bg);
    color: var(--beacon-active-nav-text);
}

header.beaconbar {
    margin-bottom: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000; /* necessary for chart libraries */
}

.rs-content h1 {
    margin-bottom: 20px;
    font-size: 32px;
}

main.rs-content,
aside.rs-sidebar {
    margin-left: 20px;
}

aside.rs-sidebar.navigation-sidebar {
    position: fixed;
}

main.rs-content.filtered-content {
    padding-left: 280px;
}

main.rs-content.fullwidth {
    padding-left: 0;
}

section.rs-container.loading {
    background-color: red;
}

footer.rs-footer {
    margin-top: 40px;
    background-color: #efefef;
}

.loading-gimmick {
    margin: 25px;
    min-height: 950px;
}

.loading-gimmick p {
    font-size: 25px;
    font-weight: 400;
}

.rs-sidenav-nav>.rs-sidenav-item {
    padding-left: 20px;
}

.rs-sidenav-nav>.rs-sidenav-item.active {
    font-weight: bold;
}

.rs-sidenav-nav>.rs-sidenav-item.pending {
    padding-left: 40px;
}

.publisher-picker {
    padding: 16px 100px 8px 0;
}

.rs-picker-select-menu {
    z-index: 10000;
}

.logo {
    padding-right: 15px;
}

.home-stat {
    float: left;
    margin: 0 20px 20px 0;
    background: #efefef;
    padding: 5px;
    min-width: 130px;
}

.home-stat h5 {
    font-size: 11px;
}

.home-stat h2 {
    font-size: 36px;
}

.home-stat p {
    font-size: 10px;
}